import React, { useState, useEffect } from 'react';
import { Box, Button, LinearProgress, Paper, Snackbar, Stack } from '@mui/material';
import AuthCard from './AuthenticatorCard';
import AuthDialog from './AuthenticatorDialog';
import Api from "../api/api";
import Config from '../Config/config';
import { KeyboardReturn, QuizOutlined } from '@mui/icons-material';
import "./Main.css";

const _SESSION_DATE_IN_MILLISECONDS = Config.MAX_SESSION_IN_MINUTES * 60 * 1000;

export default function Main() {
    const [validToken, setValidToken] = useState(null);
    const [resultUserModel, setResultUserModel] = useState(null);
    const [rsaModalOpen, setRsaModalOpen] = useState(false);
    const [hspd12ModalOpen, setHspd12ModalOpen] = useState(false);
    const [enterpriseModalOpen, setEnterpriseModalOpen] = useState(false);
    const [snackBarMessengerOpen, setSnackBarMessengerOpen] = useState(false);
    const [snackBarMessengerText, setSnackBarMessengerText] = useState(null);

    const validateTokenDate = (date) => {
        var inputDateTime = new Date(Date.parse(date));
        var currentDateTime = new Date();
        var timeDifference = currentDateTime - inputDateTime;
        var maxTimeDifferenceAllowed = currentDateTime - _SESSION_DATE_IN_MILLISECONDS;
        var currentInputTime = currentDateTime - timeDifference;

        ///THIS LOOKS BACKWARDS, BUT IT IS COUNTING THE TIME 'TICKS' IN MILLISECONDS
        /// SO THE currentInputTime SHOULD BE GREATER THAN the maxTimeDifferenceAllowed
        /// WHICH WILL ALWAYS BE THE MINIMUM PAST VALUE... IF THE currentInputTime
        /// IS LESS THAN, IT MEANS THAT THERE ARE FEWER 'TICKS' WHICH MEANS IT'S IN THE PAST
        /// BEYOND THE ALLOWED SESSION LIMITS
        if (date !== null && currentInputTime >= maxTimeDifferenceAllowed) {
            setValidToken(true);
            return true;
        } else {
            setValidToken(false);
            return false;
        }
    };

    useEffect(() => {
        async function fetchUserData() {
          Api.getUserModel().then((result) => {
            if(result){
                setResultUserModel(result);
                validateTokenDate(result.created);
            } else {
                setValidToken(false);
            }
          });
        }

        async function registerPiv() {
            Api.registerPiv().then((result) => {
              if(result) {
                  if(!result.hasError) {
                    //SUCCESSFUL REGISTER 
                    //  RELOAD THE USER TOKEN TO VIEW AUTHENTICATOR LISTING
                    Api.registerPivSuccess().then((responseBack) => {
                        if(responseBack && !responseBack.hasError) {
                            //REDIRECT
                            window.location.replace("/?key=" + responseBack.response);
                        }
                        else {
                            setValidToken(false);
                            window.location.replace("/");
                        }
                    });
                  } else {
                    //FAILED PIV REGISTER, SHOW SNACKBAR
                    
                    //window.location.href = "/";
                    setSnackBarMessengerText(result.response);
                    setSnackBarMessengerOpen(true);
                    setValidToken(false);
                  }
              } else {
                  setValidToken(false);
                  window.location.replace("/");
              }
            });
          }

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        let keyValue = params.key;

        if (params.key && keyValue){
            fetchUserData();
        }
        else if(params.action && params.FedID && params.token){
            setValidToken(null);
            //THIS IS WHERE WE NEED TO PERFORM PIVREG PROCESS
            registerPiv();
        }
        else {
            setValidToken(false);
        }
    }, [setResultUserModel, setValidToken]);

    const rsaRegisterButtonEvent = () => {
        if (validateTokenDate(resultUserModel.created)){
            setRsaModalOpen(true);
        }
    };

    const hspd12RegisterButtonEvent = () => {
        if (validateTokenDate(resultUserModel.created)){
            setHspd12ModalOpen(true);
        }
    };

    const enterpriseRegisterButtonEvent = () => {
        if (validateTokenDate(resultUserModel.created)){
            setEnterpriseModalOpen(true);
        }
    };

    const returnToNncamsEvent = () => {
        window.location.href = Config.NNCAMS_URL;
    };

    const buildMailer = (subject, body) => {
        window.location.href = "mailto:" + Config.HELPLINE_EMAIL
        + (subject ? "?subject=" + subject : "")
        + (subject && body ? "&body=" + body : "");
    };

    const rsaModal = () => (
        <AuthDialog 
            advanceButtonText={"Contact Helpline"}
            advanceCallback={() => { buildMailer("NNCAMS Request RSA Fob", "Request RSA Fob for NNCAMS Access"); }}
            cancelCallback={() => { setRsaModalOpen(false); }}
            closeCallback={() => { setRsaModalOpen(false); }}
            dialogOpen={rsaModalOpen}
            dialogHeader={"RSA Fob Login"}
            dialogBodyText={
                resultUserModel?.hasRSAToken ?
                //THERE MAY BE A FUTURE WHERE WE INTEGRATE MORE RSA FOB INFORMATION HERE...
                "Use your issued RSA token to sign into NNCAMS & supported web applications." : 
                "Contact the NNSA Helpline to request or register a RSA fob."
            }
            hasAuthenticator={resultUserModel?.hasRSAToken}
        />
    );

    const hspd12Modal = () => (
        <AuthDialog 
            advanceButtonText={"Continue to HSPD-12 Registration"}
            advanceCallback={() => { window.location.href= ("https://pivreg-dev.nn.ornl.gov/?NewUser=false"); }} //Config.NNCAMS_URL + "/Requests/RegisterBadge"); }}
            cancelCallback={() => { setHspd12ModalOpen(false); }}
            closeCallback={() => { setHspd12ModalOpen(false); }}
            dialogOpen={hspd12ModalOpen}
            dialogHeader={"HSPD-12 Badge Login"}
            dialogBodyText={
                "Use your government issued HSPD-12 badge to sign into NNCAMS & supported web applications." +
                (!resultUserModel?.hasSmartCard ?
                    " Ensure that your HSPD-12 badge is inserted into your computer's badge reader before you attempt to register." : "")
            }
            hasAuthenticator={resultUserModel?.hasSmartCard}
        />
    );

    const enterpriseModal = () => (
        <AuthDialog 
            advanceButtonText={"Contact Helpline"}
            advanceCallback={() => { buildMailer("NNCAMS Request Enterprise Login", "Request Enterprise Login for NNCAMS Access"); }}
            cancelCallback={() => { setEnterpriseModalOpen(false); }}
            closeCallback={() => { setEnterpriseModalOpen(false); }}
            dialogOpen={enterpriseModalOpen}
            dialogHeader={"Enterprise Login"}
            dialogBodyText={
                "Use your home organizations credentials to sign into NNCAMS & supported web applications, using Azure Active Directory."
                + (!resultUserModel?.hasAzureAD ? 
                    " Contact the NNSA Helpline to request Enterprise Login access." : 
                    "")
            }
            hasAuthenticator={resultUserModel?.hasAzureAD}
        />
    );

    const validTokenForm = () => (
        <div>
            <Box sx={{ width: '50%', marginLeft: '2em' }}>
                <p className='userFullName'>{ resultUserModel?.firstName + " " + resultUserModel?.lastName }</p>
                <p className='authenticatorIntroHeader'>Your Account Authentication Methods</p>
                <p className='authenticatorIntroText'>Sign in to NNCAMS supported web applications using the following authenticators:</p>
            </Box>
            <Box sx={{ width: '50%', marginLeft: '2em' }}>
                <Stack spacing={2}>
                    <AuthCard 
                        detailText={'Access sites using your \'.gov\' or \'.mil\' organization account'}  
                        hasAuthenticator={resultUserModel?.hasAzureAD}
                        registerButtonEvent={enterpriseRegisterButtonEvent}                        
                        title={'Enterprise'} 
                    />
                    <AuthCard
                        detailText={'Access sites using your issued HSPD-12 badge'}
                        hasAuthenticator={resultUserModel?.hasSmartCard} 
                        registerButtonEvent={hspd12RegisterButtonEvent}                        
                        title={'HSPD-12'}
                    />
                    <AuthCard 
                        detailText={'Access sites using your issued RSA token'}
                        hasAuthenticator={resultUserModel?.hasRSAToken}
                        registerButtonEvent={rsaRegisterButtonEvent}
                        title={'RSA'}
                    />
                </Stack>
            </Box>
        </div>
    );

    const invalidTokenForm = () => (
        <div>
            <Box sx={{ width: '80%', marginLeft: '2em' }}>
                <h3>Expired or Invalid Request</h3>
                <p>Your request was unable to be processed.</p>
                <p>Please try again from <a href={ Config.NNCAMS_URL }>NNCAMS</a> or contact <a href={"mailto:" + Config.HELPLINE_EMAIL }>NNSA Helpline</a> (Phone: 865-574-7911).</p>
            </Box>
        </div>
    );

    const loaderPanel = () => (
        <div>
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
                <p className='loaderTitle'>Processing User Request</p>
                <LinearProgress />
            </Box>
        </div>
    );

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setSnackBarMessengerOpen(false);
      };

    const snackBarMessenger = () => (
        <div>
            <Snackbar
                autoHideDuration={7000}
                message={snackBarMessengerText}
                onClose={handleSnackbarClose}
                open={snackBarMessengerOpen}
            />
        </div>
    );

    const mainFormContent = () => {

        let resultComponent = null;

        switch(validToken){
            case null:
                resultComponent = loaderPanel();
                break;
            case true:
                resultComponent = validTokenForm();
                break;
            case false:
                resultComponent = invalidTokenForm();
                break;
            default:
                resultComponent = invalidTokenForm();
                break;
        }

        return (
            <div>
                { resultComponent }

                {/* DON'T DISPLAY THE BUTTONS ON THE LOADER */}
                {validToken !== null ? 
                    <Box className='nncamsBaseButtons'>
                        <Button
                            onClick={returnToNncamsEvent}
                            variant="outlined"
                            startIcon={<KeyboardReturn />}
                        >
                            Return to NNCAMS
                        </Button>
                        <Button
                            onClick={() => { buildMailer(); }}
                            variant="outlined"
                            startIcon={<QuizOutlined />}
                        >
                            Contact Helpline
                        </Button>
                    </Box>        
                : null }
            </div>
        );
    };
    
    return (
        <div>
            <Box sx={{ width: '100%', backgroundColor: '#AB0005' }}>
                <p className='textTitle'>NNCAMS Authenticator</p>
            </Box>

            { mainFormContent() }

            { snackBarMessenger() }

            { rsaModalOpen ? rsaModal() : null }
            { hspd12ModalOpen ? hspd12Modal() : null }
            { enterpriseModalOpen ? enterpriseModal() : null }

            <Paper sx={{marginTop: 'calc(10% + 20px)',
                    backgroundColor: '#eeeeee',
                    position: 'fixed',
                    bottom: 0,
                    width: '100%'}}
            >
                <Box className='footerBox' sx={{ paddingBottom: '1em', paddingLeft: '2em', paddingTop: '0.5em', paddingRight: '2em' }}>
                    <div className='footer'>
                        <div>
                            <a href='https://www.energy.gov/'>U.S Department of Energy</a>
                            {" | "}
                            <a href='https://www.energy.gov/nnsa/national-nuclear-security-administration'>National Nuclear Security Administration</a>
                            <br />
                            Defense Nuclear Nonproliferation | Washington, DC 20585
                        </div>
                        <div>
                            Please direct questions to <a href={"mailto:" + Config.HELPLINE_EMAIL}>{Config.HELPLINE_EMAIL}</a> or call (865) 574-7911 for assistance.
                        </div>
                    </div>
                </Box>
            </Paper>
        </div>
    );
}