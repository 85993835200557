import './App.css';
import Main from './Components';

function App() {
  return (
    <Main />
  );
}

export default App;
