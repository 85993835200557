import React from 'react';
import { Button, Paper} from '@mui/material';
import { HighlightOff, Quiz, TaskAlt } from '@mui/icons-material';
import "./AuthCard.css";

export default function AuthCard({ detailText, hasAuthenticator, registerButtonEvent, title }) {
    return (           
        <Paper elevation={2} sx={{ p: 1.2 }} >
            <div className='content'>
                <div className='details'>
                    {hasAuthenticator ? 
                        <TaskAlt 
                                sx={{ 
                                    color: 'green',
                                    fontSize: 60
                                }} /> 
                            : 
                            <HighlightOff 
                                sx={{ 
                                    color: 'red',
                                    fontSize: 60 
                            }}
                        />
                    }
                    <p className='authenticatorTitle'>{title}</p>
                    <p>{detailText}</p>
                </div>
                <div>
                    <Button
                        //disabled={hasAuthenticator}  //ALWAYS SHOW THE ABILITY TO VIEW MORE INFORMATION
                        onClick={registerButtonEvent}                            
                        variant="contained"
                        endIcon={<Quiz />}
                    >
                        Info
                    </Button>
                </div>
            </div>
        </Paper>    
    );
}