const Api = {
    async getUserModel() {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        let keyValue = params.key;

        const response = await fetch('UserAccess?' + new URLSearchParams({
            key: keyValue
        }));

        const data = await response.json();
        return data;
    },

    async registerPiv() {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        let actionValue = params.action;
        let fedIdValue = params.FedID;
        let tokenValue = params.token;

        const response = await fetch('Requests/RegisterBadge?' + new URLSearchParams({
            action: actionValue,
            FedID: fedIdValue,
            token: tokenValue
        }));

        const data = await response.json();
        return data;
    },

    async registerPivSuccess() {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        
        const response = await fetch('Requests/ReloadUserAccess?' + new URLSearchParams({
            
        }), { method: 'POST' });

        const data = await response.json();
        return data;
    },
}

export default Api;