import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import "./AuthDialog.css";

export default function AuthDialog({ dialogOpen, dialogHeader, dialogBodyText, closeCallback, cancelCallback, advanceCallback, advanceButtonText, hasAuthenticator }) {

    const cancelButtonText = hasAuthenticator ? "Close" : "Cancel";

    return (
        <div>
            <Dialog
                open={dialogOpen}
                onClose={() => { closeCallback(); } }
            >
                <DialogTitle id="alert-dialog-title">
                    {dialogHeader}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogBodyText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="error" onClick={() => { cancelCallback(); }}>
                        {cancelButtonText}
                    </Button>
                    {!hasAuthenticator ? 
                        <Button variant="contained" onClick={() => { advanceCallback(); }} autoFocus>
                            {advanceButtonText}
                        </Button> : null }
                </DialogActions>
            </Dialog>
        </div>
    );
}